import { FC, useCallback } from 'react';

import { Menu } from '@/shared/icons';
import { DatepickerFilter, SearchFilter } from '@/shared/components';
import { CategoryFilters } from '@/features/categories/components/category-filters';
import { UniversityFilters } from '@/features/universities/components/university-filters';
import { jsonParse, jsonStringify } from '@/shared/utils';
import { DEFAULT_TABLE_ITEMS_SIZE, FILTERS_TABLE_KEY, PAGINATION_KEY } from '@/shared/constants';
import { useQueryParams, useToggle } from '@/shared/hooks';
import { ExportButton } from '@/shared/components/export-button';

import styles from './filters.module.scss';
import clsx from 'clsx';

type FiltersProps = {
  shownSearch?: boolean;
  shownDateRange?: boolean;
  shownCategories?: boolean;
  shownUniversities?: boolean;
  disableExport: boolean;
  endpoint: string;
};

export const Filters: FC<FiltersProps> = ({
  shownSearch,
  shownDateRange,
  shownCategories,
  shownUniversities,
  disableExport,
  endpoint,
}) => {
  const { queryParams, setQueryParams } = useQueryParams();
  const [value, , setValue] = useToggle(true);

  const handleSubmitCategoryFilter = (categories: string[]) => {
    setQueryParams((prev) => {
      const prevFilters = jsonParse(prev?.[FILTERS_TABLE_KEY]);

      return {
        [FILTERS_TABLE_KEY]: jsonStringify({
          ...(prevFilters ? prevFilters : {}),
          categoryIds: categories,
        }),
        // When categories have been applied, the table should start from the first page
        [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
      };
    });
  };

  const handleSubmitUniversityTypesFilter = (universityTypes: string[]) => {
    setQueryParams((prev) => {
      const prevFilters = jsonParse(prev?.[FILTERS_TABLE_KEY]);

      return {
        [FILTERS_TABLE_KEY]: jsonStringify({
          ...(prevFilters ? prevFilters : {}),
          universityTypes: universityTypes,
        }),
        // When categories have been applied, the table should start from the first page
        [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
      };
    });
  };

  const handleSubmitDateRangeFilter = (dateRange?: [string, string]) => {
    setQueryParams((prev) => {
      const prevFilters = jsonParse(prev?.[FILTERS_TABLE_KEY]);

      return {
        [FILTERS_TABLE_KEY]: jsonStringify({
          ...(prevFilters ? prevFilters : {}),
          dateRange: dateRange || [],
        }),
        // When the Data Range filter have been applied, the table should start from the first page
        [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
      };
    });
  };

  const clearSearchFilter = useCallback(() => {
    setQueryParams((prev) => {
      const prevFilters = jsonParse(prev?.[FILTERS_TABLE_KEY]);

      return {
        [FILTERS_TABLE_KEY]: jsonStringify({
          ...(prevFilters ? prevFilters : {}),
          keyword: '',
        }),
      };
    });
  }, [setQueryParams]);

  const handleSubmitSearch = useCallback(
    (keyword = '') => {
      const MIN_LENGHT = 2;

      if (!keyword) {
        clearSearchFilter();
        return;
      }

      if (keyword.length < MIN_LENGHT) return;

      setQueryParams((prev) => {
        const prevFilters = jsonParse(prev?.[FILTERS_TABLE_KEY]);

        return {
          [FILTERS_TABLE_KEY]: jsonStringify({
            ...(prevFilters ? prevFilters : {}),
            keyword,
          }),
          // When search have been applied, the table should start from the first page
          [PAGINATION_KEY]: jsonStringify({ itemsPerPage: DEFAULT_TABLE_ITEMS_SIZE, page: 0 }),
        };
      });
    },
    [clearSearchFilter, setQueryParams],
  );

  const handleFilterToggle = () => {
    setValue((x: boolean) => !x);
  };

  const searchedKeyword = jsonParse<{ keyword: string }>(queryParams[FILTERS_TABLE_KEY])?.keyword;
  const selectedCategories = jsonParse<{ categoryIds?: string[] }>(queryParams[FILTERS_TABLE_KEY])?.categoryIds;
  const selectedDateRange = jsonParse<{ dateRange?: [string, string] }>(queryParams[FILTERS_TABLE_KEY])?.dateRange;
  const selecteduniversityTypes = jsonParse<{ universityTypes?: string[] }>(
    queryParams[FILTERS_TABLE_KEY],
  )?.universityTypes;

  return (
    <>
      <button className={styles['filter-button']} onClick={handleFilterToggle}>
        <Menu className={styles['filter-button-icon']} width="1.5rem" height="1.5rem" />
        <span className={styles['filter-button-label']}>Filters</span>
      </button>
      <div className={clsx(styles['filters'], !value && styles['is-visible'])}>
        {shownSearch && (
          <SearchFilter searchedKeyword={searchedKeyword} onSubmit={handleSubmitSearch} onClear={clearSearchFilter} />
        )}

        {shownDateRange && (
          <DatepickerFilter selectedRange={selectedDateRange} onSubmit={handleSubmitDateRangeFilter} />
        )}

        {shownCategories && (
          <CategoryFilters selectedSubcategoryIds={selectedCategories} onSubmit={handleSubmitCategoryFilter} />
        )}

        {shownUniversities && (
          <UniversityFilters
            selectedUniversityTypes={selecteduniversityTypes}
            onSubmit={handleSubmitUniversityTypesFilter}
          />
        )}

        <ExportButton disabled={disableExport} endpoint={endpoint} />
      </div>
    </>
  );
};
